import React, { useState } from 'react';
import {Modal, message, Button} from 'antd';
import {createFunction, deleteFunction, updateFunction} from "src/api/functionApi";
import {useNavigate} from "react-router-dom";
import {deleteIngredient} from "src/api/ingredientApi";
import {FUNCTION} from "src/constants/appConstants";

interface DeleteProps {
    open: boolean,
    onConfirm: (data: any) => void;
    onCancel: () => void;
    type: string
    id: string
}

const Delete: React.FC<DeleteProps> = ({open, onConfirm, onCancel, type, id}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = (id: string, type : string) => {
        setLoading(true)
        const loadingMessageKey = message.loading({
            content: `Deleting ${type === FUNCTION ? 'Function' : 'Ingredient'}...`,
            key: 'loadingMessage',
            duration: 0, // Set duration to 0 to prevent automatic dismissal
            onClose: () => setLoading(false), // Close the loading message on user interaction
        });
        const deleteItem = type === FUNCTION ? deleteFunction : deleteIngredient;
        deleteItem(id)
            .then((response: any) => {
                loadingMessageKey();
                setLoading(false);
                if(!response) {
                    message.error(`Error deleting ${type}`, 5);
                    return;
                }
                onConfirm(response);
                message.success(`${type} deleted successfully`, 5);
            })
    };

    return (
        <Modal
            title="Confirm Delete"
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="delete" type="primary" danger onClick={() => handleDelete(id, type)}>
                    Delete
                </Button>,
            ]}
        >
            <p>Are you sure you want to delete this {type} ?</p>
        </Modal>
    );
};

export default Delete;
