import React, { useState, useEffect } from 'react';
import {Modal, Form, Input, message} from 'antd';
import { Function } from "src/types/function";
import {createFunction, updateFunction} from "src/api/functionApi";
import {useNavigate} from "react-router-dom";
import {Actions} from "src/constants/enums";

interface EditFunctionsProps {
    open: boolean,
    onOk: (data: any) => void;
    onCancel: () => void;
    initialData?: Function | undefined
    newFunctionId? : string
}

const EditFunctions: React.FC<EditFunctionsProps> = ({open, onOk, onCancel, initialData, newFunctionId}) => {
    const [form] = Form.useForm();
    const [okText, setOkText] = useState(Actions.UPDATE);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Set the form field values based on initialData
        if(initialData) {
            setOkText(Actions.UPDATE);
            setUpdate(true);
            form.setFieldsValue({
                ...initialData,
            });
        }
        else {
            setOkText(Actions.ADD)
            setUpdate(false);
            form.resetFields();
        }
    }, [initialData, newFunctionId]);

    const handleEdit = (update: boolean, newFunctionId: string | undefined) => {
        form.validateFields().then((values) => {
            setLoading(true);
            values.id = newFunctionId;
            // Display the loading message with a close button
            const loadingMessageKey = message.loading({
                content: `${update ? 'Updating' : 'Creating'} function`,
                key: 'loadingMessage',
                duration: 0, // Set duration to 0 to prevent automatic dismissal
                onClose: () => setLoading(false), // Close the loading message on user interaction
            });
            const editFunction = update ? updateFunction : createFunction;
            editFunction(values)
                .then((response) => {
                    loadingMessageKey();
                    setLoading(false);
                    if(!response) {
                        message.error(`Error ${update ? 'updating' : 'creating'} function`, 5);
                        return;
                    }
                    message.success(`Function ${update ? 'updated' : 'created'} successfully`, 5);
                    onOk(response);
                    form.resetFields();
                })
        });
    };

    return (
        <Modal
            open={open}
            okText={okText}
            onOk={() => handleEdit(update, newFunctionId)}
            onCancel={onCancel}
        >
            <Form
                form={form}
                name="edit-function-form"
                layout="vertical"
            >
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditFunctions;
