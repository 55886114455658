import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";
import {DownloadOutlined} from '@ant-design/icons';
import {getIngredientFile} from "src/api/ingredientApi";

export function DownloadButton(props: { ids: string[], fileType: string, filteredCategory: string[]  }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const onClick = () => {
        setLoading(true);
        getIngredientFile(props)
            .then(url => {
                setLoading(false);
                if (!url)
                    return navigate("/not-found")
                window.open(url, "_blank", "noreferrer,noopener");
            })
            .catch(reason => {
                setLoading(false);
                return navigate("/not-found")
            })
    }


    return <span style={{marginRight: '0.5rem'}}>

            <Button type="primary" icon={<DownloadOutlined/>} loading={loading} onClick={() => {
                onClick()
            }}> {props.fileType} </Button>
        </span>
}