import React from "react";
import {GREEN, GREY, PRIMARY_COLOR, RED, YELLOW, WHITE, CRIMSON_RED} from "src/constants/colorContants";
import {ACCEPTABILITY_ICON_FONT_SIZE} from "src/constants/sizeContants";
import flatten from "fork-ts-checker-webpack-plugin/lib/utils/array/flatten";

// Common
export const displayFlex: React.CSSProperties = {
    display: "flex",
}

export const displayBlock: React.CSSProperties = {
    display: "block",
}

// Icons
export const deleteTwoToneStyle: React.CSSProperties = {
    fontSize: "20px",
    cursor: "pointer"
}

export const minusCircleTwoTone: React.CSSProperties = {
    fontSize: "16px",
    color: CRIMSON_RED,
}

// App
// Layout
export const appLayoutStyle: React.CSSProperties = {
    backgroundColor: PRIMARY_COLOR,
    paddingTop: "1em",
}
// Header
export const appHeaderStyle: React.CSSProperties = {
    height: 'fit-content',
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1em',
    color: PRIMARY_COLOR,
    backgroundColor: WHITE,
};

// Content
export const appContentStyle: React.CSSProperties = {
    padding: '10px',
};

// Footer
export const appFooterStyle: React.CSSProperties = {
    height: 'fit-content',
    color: WHITE,
    backgroundColor: PRIMARY_COLOR,
};

// Edit Ingredients
// Page
export const editIngredientsPageStyle: React.CSSProperties = {
    width: '100%',
}

// Header
export const editIngredientsHeaderStyle: React.CSSProperties = {
    background: WHITE,
    padding: "1em",
    display: "flex",
    width: '100%',
    justifyContent: 'space-between'
}

// Layout
export const editIngredientsLayoutStyle: React.CSSProperties = {
    display: "flex",
    flex: "1",
    padding: "1em",
}

// Content
export const editIngredientsContentStyle: React.CSSProperties = {
    background: WHITE,
    paddingRight: "1em",
}

// Sider
export const editIngredientsSiderStyle: React.CSSProperties = {
    background: WHITE,
}

// Ingredients Card
// Type And Status Search
export const ingredientsCardTypeAndStatusStyle: React.CSSProperties = {
    width: "240px",
}

// Ingredients Card
// Functions
export const ingredientsCardFunctionsStyle: React.CSSProperties = {
    width: "380px",
}

// Acceptability Icons
// QuestionCirlce
export const questionCircleStyle: React.CSSProperties = {
    fontSize: ACCEPTABILITY_ICON_FONT_SIZE,
    color: GREY,
}

// CheckCircle
export const checkCircleStyle: React.CSSProperties = {
    fontSize: ACCEPTABILITY_ICON_FONT_SIZE,
    color: GREEN,
}

// CloseCircle
export const closeCircleStyle: React.CSSProperties = {
    fontSize: ACCEPTABILITY_ICON_FONT_SIZE,
    color: RED,
}

// ExclamationCircle
export const exclamationCircleStyle: React.CSSProperties = {
    fontSize: ACCEPTABILITY_ICON_FONT_SIZE,
    color: YELLOW,
}

// SearchBar
export const searchBarStyle: React.CSSProperties = {
    color: PRIMARY_COLOR,
    flexGrow: 2,
}

// Search Container
// Add Ingredient Button
export const searchContainerAddIngredientButtonStyle: React.CSSProperties = {
    color: 'white',
    textDecoration: 'none',
}

// Search Filters
export const searchFiltersStyle: React.CSSProperties = {
    color: PRIMARY_COLOR,
    width: '50%',
}

// Navigation
// Admin Toggle
export const navigationAdminToggleStyle: React.CSSProperties = {
    position: 'relative',
    margin: 'auto',
    marginRight: '0'
}

// Ingredients Table
export const ingredientsTableStyle: React.CSSProperties = {
    margin: "1em",
}