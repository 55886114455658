import React, {useEffect, useState} from "react";
import {Button, Divider, List, Spin} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {getFunctions} from "src/api/functionApi";
import {Function} from "src/types/function";
import EditFunctions from "src/components/EditFunctions";
import "../css/style.css";
import {GLOSSARY_PAGE} from "src/constants/appConstants";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";

declare global {
    interface String {
        capitalize(): string;
    }
}

String.prototype.capitalize = function (this: string) {
    return this.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

interface GlossaryFunctionsProps {
    admin: boolean;
}

export const GlossaryFunctions : React.FC<GlossaryFunctionsProps> = ({admin}) => {
    const navigate = useNavigate();
    const initialTime = Date.now();
    useEffect(() => {
        const metricsPublisher = getMetricsPublisher();
        metricsPublisher.publishTimerMetric(GLOSSARY_PAGE,
          MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
        metricsPublisher.publishCounterMetric(GLOSSARY_PAGE, MetricKey.CLICKS, 1);
    }, []);
    // Functions Grouped by Alphabets
    // 0th index -> a, 1st index -> b, etc.
    const [funcs, setFuncs] = useState<Array<Array<Function>>>(Array.of())
    const [funcList, setFuncList] = useState<Function[]>([]);
    const [latestFunctionId, setLatestFunctionId] = useState<number>();
    const [isAddFunctionModalVisible, setIsAddFunctionModalVisible] = useState(false);

    useEffect(() => {
        getFunctions()
            .then(functions => {
                if(!functions)
                    setFuncs([])
                else {
                    setFuncList(functions);
                    const functionsByAlphabet: Array<Array<Function>> = new Array<Array<Function>>();
                    for (const func of functions) {
                        const firstLetter = func.name.toUpperCase().charCodeAt(0) - 65;
                        const currentArray = functionsByAlphabet[firstLetter];
                        // Check if the current array is undefined or null, and initialize it if needed
                        if (!Array.isArray(currentArray)) {
                            functionsByAlphabet[firstLetter] = [func];
                        } else {
                            functionsByAlphabet[firstLetter] = [...currentArray, func];
                        }
                    }
                    // Sort each array in dictionary order
                    for (let i = 0; i < functionsByAlphabet.length; i++) {
                        if (Array.isArray(functionsByAlphabet[i])) {
                        functionsByAlphabet[i] = functionsByAlphabet[i].sort((a, b) => a.name.localeCompare(b.name));
                        }
                    }
                    setFuncs(functionsByAlphabet)
                }
            })
    }, []);

    const openAddFunctionModal = () => {
        const latestFuncId = funcList.reduce((prev, current) => {
            const currentId = parseInt(current.id, 10);
            return !isNaN(currentId) ? Math.max(parseInt(prev, 10), currentId).toString() : prev;
        }, funcList[0].id);
        setLatestFunctionId(parseInt(latestFuncId, 10) + 1);
        setIsAddFunctionModalVisible(true);
    };

    const handleAddFunction = (response: any) => {
        setIsAddFunctionModalVisible(false);
        if (response?.id) navigate(`/glossary/${response?.id}`);
        else navigate(`/glossary`);
    };

    return <div className={"glossary_functions-page"}>
        <div className={"glossary-functions-header"}>
            <h2>Glossary</h2>
            {admin && <Button type="primary" onClick={() => openAddFunctionModal()}>Add</Button>}
        </div>
        <Divider/>
        <Spin spinning={funcs.length === 0}>
        <div>
        {funcs.map((functions, i) => {
            return <>
                <p>{String.fromCharCode(i + 65)}</p>
                <List
                    dataSource={functions}
                    grid={{
                        gutter: 10,
                        column: 4
                    }}
                    renderItem={(item) => <List.Item><Link to={item.id}>{item.name.capitalize()}</Link></List.Item>}
                />
            </>
        })}
        </div>
        </Spin>
        {admin && <EditFunctions
            open={isAddFunctionModalVisible}
            onOk={handleAddFunction}
            onCancel={() => setIsAddFunctionModalVisible(false)}
            newFunctionId={(latestFunctionId ?? + 1).toString()}
        />}
    </div>
}