import React from "react";
import {Acceptability, Ingredient} from "src/types/ingredient";
import {capitalize, convertDateUSFormat, convertTime, getName} from "src/utils/commonUtils";
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, QuestionCircleFilled} from "@ant-design/icons";
import {Popover} from "antd";
import {ACCEPTABILITY, CREATED_BY, UPDATED_ON, FUNCTION_CONSTANT, MASTERS, CREATED_ON} from "src/constants/appConstants";
import {AcceptabilityType} from "src/constants/enums";
import {
    checkCircleStyle,
    closeCircleStyle,
    exclamationCircleStyle,
    questionCircleStyle
} from "src/constants/styleConstants";

export const alphabets = [String.fromCharCode(35), ...Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 65))]

export const getColumnValue = (record: Ingredient, column: string) => {
    let value = record[column as keyof Ingredient]
    if(column === UPDATED_ON) {
        if(value === undefined) {
            value = record.createdOn;
        }
    }
    let UPDATED_BY;
    switch (column) {
        case CREATED_BY:
            return <><p>{getName(value)}</p>{value}</>
        case ACCEPTABILITY:
            return <p>{value?.map((item:{[key: string]:any})=><p>{item?.type} ({item?.status})</p>)}</p>
        case UPDATED_BY:
            const creationDate = value.split(' ')[0];
            const creationTime = value.split(' ')[1];
            return <>Published on {creationDate} at {convertTime(creationTime)}</>
        case UPDATED_ON:
            const updateDate = value.split(' ')[0];
            return <>{convertDateUSFormat(updateDate)} by <br />{getName(record.updatedBy)}</>
        case FUNCTION_CONSTANT:
            return <>{
                record?.[column]?.map((item:{[key:string]:any})=><p>{item?.name}</p>)
            }</>
        case MASTERS:
            return <>{
                Object.keys(record.masters).map(masterKey =>
                    <p>{capitalize(masterKey)}: <br/>
                        <ul className="associated-ingredients-list">{record.masters[masterKey].map((masterRecord: { [x: string]: string; }) =>
                            <li>{masterRecord["name"].toUpperCase()}<br/></li>)}
                        </ul>
                    </p>
                )}</>   
        case CREATED_ON:
            const createdOn = record.createdOn;
            const dateCreated= createdOn.split(' ')[0];
            return <>{convertDateUSFormat(dateCreated)} by <br />{getName(record.createdBy)}</>
        default:
            return <p>{value}</p>
    }
}

export const getAcceptabilityIcon = (acceptability: Acceptability | undefined) => {
    if (!acceptability) return <QuestionCircleFilled style={questionCircleStyle}/>
    switch (acceptability.status) {
        case AcceptabilityType.ACCEPTABLE:
            return <CheckCircleFilled style={checkCircleStyle}/>
        case AcceptabilityType.UNACCEPTABLE:
            return <CloseCircleFilled style={closeCircleStyle}/>
        case AcceptabilityType.ACCEPTABLE_WITH_QUALIFIER:
        case AcceptabilityType.UNACCEPTABLE_WITH_QUALIFIER:
            return <Popover content={acceptability?.qualifier}>
                <ExclamationCircleFilled style={exclamationCircleStyle}/>
            </Popover>
        default:
            return <QuestionCircleFilled style={questionCircleStyle}/>
    }
}