import React, {useState} from "react";
import CollapsableForm from "src/components/common/CollapsableForm";
import {Button, Checkbox, Collapse, Input, Select, Space, Tabs} from "antd";
import {displayBlock, displayFlex} from "src/constants/styleConstants";
import {SelectModes} from "src/constants/appConstants";

const { Option} = Select;
const { Panel } = Collapse;

interface MastersCardProp {
    data: string[];
    cardType: string
    updateData: (newData: string[]) => void;
}

const MastersCard: React.FC<MastersCardProp> = ({data, cardType, updateData}) => {
    const [newItem, setNewItem] = useState("");

    const handleAddNewItem = () => {
        if (newItem.trim() !== "" && !data.includes(newItem)) {
            const updatedData = [...data, newItem];
            updateData(updatedData);
            setNewItem("");
        }
    };

    return (
        <CollapsableForm title={cardType}>
            <Tabs defaultActiveKey={`all${cardType}`} items={[
                {
                    key: `all${cardType}`,
                    label: `All ${cardType}`,
                    children: (
                        <Checkbox.Group style={displayBlock}>
                            {data && data.map((value) => (
                                <div key={value}>
                                    <Checkbox value={value} style={displayFlex}>{value}</Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    ),
                },
            ]} />
            <Collapse accordion bordered={false} expandIcon={() => null}>
                <Panel header={`Add New ${cardType}`} key={`addNew${cardType}`}>
                    <Space direction="vertical">
                        <Input
                            value={newItem}
                            onChange={(e) => setNewItem(e.target.value)}
                            placeholder="New Type"
                        />
                        {
                            cardType === "Classifications" ? <Select
                                style={displayFlex}
                                placeholder={`Select ${cardType}`}
                                mode={SelectModes.multiple}
                            >
                                {
                                    data && data.map(
                                        (value) => {
                                            return <Option value={value}>{value}</Option>
                                        }
                                    )
                                }
                            </Select> : null
                        }
                        <Button type="primary" onClick={handleAddNewItem}>Add</Button>
                    </Space>
                </Panel>
            </Collapse>
        </CollapsableForm>
    )
}

export default MastersCard;