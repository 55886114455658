import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Menu} from "antd";
import {
    GLOSSARY_PAGE,
    HOME_PAGE,
} from "src/constants/appConstants";
import {Switcher} from "src/components/common/Switcher";

interface NavigationProps {
    admin: boolean;
}

export const Navigation : React.FC<NavigationProps> = ({admin}) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <div className={"navigation-page"}>
        <div className={"navigation-bar"}>
            <Menu
                mode="horizontal"
                defaultSelectedKeys={[location.pathname]}
            >
                <Menu.Item key={HOME_PAGE} onClick={() => navigate(HOME_PAGE)}>Ingredient</Menu.Item>
                <Menu.Item key={GLOSSARY_PAGE} onClick={() => navigate(GLOSSARY_PAGE)}>Glossary</Menu.Item>
                <Switcher admin={admin}/>
            </Menu>
        </div>
    </div>
}