import {MetricInitializer, MetricPublisher} from "@amzn/wfm-client-side-metrics-helper";

let metricsPublisher: MetricPublisher | undefined = undefined;

export const getMetricsPublisher = (): MetricPublisher => {
  if (metricsPublisher === undefined) {
    return metricsPublisher = new MetricInitializer(
      'prod',
      'USAmazon',
      'IngredientsList',
      'WfmIngredientsFrontend')
      .initialise();
  }
  return metricsPublisher;
}

export const MetricKey = {
  PAGE_LOAD_LATENCY: 'PageLoadLatency',
  CLICKS: 'Clicks'
}