import React, {useEffect, useState} from "react";
import CollapsableForm from "src/components/common/CollapsableForm";
import {Button, Form, Input, message, Radio, Select, Space} from "antd";
import {MinusCircleTwoTone, PlusCircleTwoTone} from "@ant-design/icons";
import {Function} from "src/types/function";
import {
    ADD_INGREDIENT_PAGE,
    NORMAL_SEARCH,
    SelectModes,
    UNSAVED_FORM_MESSAGE,
    visibilities
} from "src/constants/appConstants";
import {createdOn, getUniqueItems} from "src/utils/commonUtils";
import {Acceptability, Ingredient, Master, MastersList} from "src/types/ingredient";
import {User} from "src/types/user";
import {createIngredient, updateIngredient} from "src/api/ingredientApi";
import {useLocation, useNavigate} from "react-router-dom";
import { clearIngredientsCache } from "src/api/cache/ingredientsCache";
import {AcceptabilityType, Visibility} from "src/constants/enums";
import {
    displayFlex,
    ingredientsCardFunctionsStyle,
    ingredientsCardTypeAndStatusStyle,
    minusCircleTwoTone
} from "src/constants/styleConstants";
import "../css/style.css";
import EditFunctions from "src/components/EditFunctions";

export const {Option} = Select;

interface IngredientsCardProp {
    id?: string;
    ingredients: Ingredient[];
    classifications: string[];
    masters: string[];
    types: string[];
    statuses: string[];
    functions: Function[];
    getFunctionsHandler: () => void;
    user: User;
}

const IngredientsCard: React.FC<IngredientsCardProp> = ({id, ingredients, classifications, masters, types, statuses, functions, getFunctionsHandler, user}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [selectedClassification, setSelectedClassification] = useState<string>("Agriculture");
    const [selectedMasters, setSelectedMasters] = useState<string[]>([]);
    const [selectedFunctions, setSelectedFunctions] = useState<string[]>([]);
    const [selectedVisibility, setSelectedVisibility] = useState(Visibility.PUBLISH);
    const [ingredient, setIngredient] = useState<Ingredient>();
    const [isUpdate, setUpdate] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [isStatusUpdated, setStatusUpdated] = useState(false);
    const [latestFunctionId, setLatestFunctionId] = useState<number>();
    const [isAddFunctionModalVisible, setIsAddFunctionModalVisible] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // Reload event
            if (isFormChanged) {
                event.returnValue = UNSAVED_FORM_MESSAGE;
                return message;
            }
        };

        const handlePopstate = (event: PopStateEvent) => {
            // Back event
            if(isFormChanged && location.pathname === ADD_INGREDIENT_PAGE) {
                const confirmed = window.confirm(UNSAVED_FORM_MESSAGE);
                if (!confirmed) {
                    // If the user clicks "Cancel," prevent the default behavior
                    history.pushState(null, "", window.location.pathname);
                }
            }
        };

        history.pushState(null, "", location.pathname);
        window.addEventListener('popstate', handlePopstate);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFormChanged]);

    useEffect(() => {
        clearIngredientsCache();
        form.resetFields();
    }, []);

    const handleFormChange = () => {
        setIsFormChanged(true);
    };

    useEffect(() => {
        form.setFieldsValue({
            masters: selectedMasters,
        });
    }, [selectedMasters]);

    useEffect(() => {
        if(id) {
            setUpdate(true);
            const ingredientDetail = ingredients.find(ingredient => ingredient.id === id);
            setIngredient(ingredientDetail);
            if(ingredient) {
                setSelectedClassification(ingredient.classification);
                setSelectedMasters(Object.keys(ingredient.masters));
                const mastersFields: {[key: string]: string []} = {};
                Object.keys(ingredient.masters).forEach((masterKey) => {
                    mastersFields[masterKey] = ingredient.masters[masterKey].map((item: Master) => item.id);
                });
                form.setFieldsValue({
                    name: ingredient.name,
                    classification: ingredient.classification,
                    masters: Object.keys(ingredient.masters),
                    acceptability: ingredient.acceptability,
                    functions: ingredient.functions.map((func: Function) => func.id),
                    status: ingredient.status,
                    visibility: ingredient.visibility,
                    ...mastersFields,
                });
            }
        }
    }, [id, ingredients, ingredient])

    const handleClassificationChange = (e: any) => {
        setSelectedClassification(e.target.value);
    };

    const handleVisibilityChange = (e: any) => {
        setSelectedVisibility(e.target.value);
    };

    const handleSelectIngredient = (ingredientIds: string[]) => {
        if (isUpdate) return;
        const existingIngredients: Ingredient[] = [];
        ingredients?.forEach((ingredient) => {
            for (const master of selectedMasters) {
                const ingredientExist = ingredient?.masters[master]
                    ?.find((childIngredient: Master) => ingredientIds.includes(childIngredient.id))
                if (ingredientExist) {
                    existingIngredients.push(ingredient);
                    break;
                }
            }
        });

        const acceptabilityMap: { [key: string]: Acceptability } = {};
        const existingAcceptability: Acceptability[] = form.getFieldValue('acceptability');
        existingAcceptability?.forEach((acceptability) => acceptabilityMap[acceptability.type] = acceptability);

        const functionIdsSet: Set<string> = new Set();
        const existingFunctions: string[] = form.getFieldValue('functions');
        existingFunctions?.forEach((funcId) => functionIdsSet.add(funcId));

        existingIngredients?.forEach(ingredient => {
            ingredient.acceptability?.forEach(acceptability => {
                if (!(acceptability.type in acceptabilityMap)) {
                    acceptabilityMap[acceptability.type] = acceptability;
                } else {
                    const currentAcceptability = acceptabilityMap[acceptability.type];
                    if (
                        acceptability.status === AcceptabilityType.ACCEPTABLE &&
                        currentAcceptability.status !== AcceptabilityType.ACCEPTABLE
                    ) {
                        acceptabilityMap[acceptability.type] = acceptability;
                    }
                }
            });
            ingredient.functions?.forEach((func: Function) => {
                functionIdsSet.add(func.id);
            });
        });
        const uniqueAcceptability = Object.values(acceptabilityMap);
        const uniqueFunctionIds = Array.from(functionIdsSet);
        form.setFieldsValue({
            acceptability: uniqueAcceptability,
            functions: uniqueFunctionIds
        })
    }

    const handleMastersChange = (selectedValues: React.SetStateAction<string[]>) => {
        setSelectedMasters(selectedValues);
    };

    const handleFunctionChange = (selectedFunctions: React.SetStateAction<string[]>) => {
        setSelectedFunctions(selectedFunctions);
    };

    const formatIngredient = (data: Ingredient) => {
        const updatedMasters: MastersList = {};
        // Iterate over keys in the masters object
        data.masters.forEach((masterType: string) => {
            let masterIds = data[masterType];
            // Convert to an array if it's a single string
            if (!Array.isArray(masterIds)) {
                masterIds = [masterIds];
            }
            updatedMasters[masterType] = masterIds.map((id: string) => {
                const foundIngredient = ingredients.find(ingredient => ingredient.id === id);
                return foundIngredient ? { id, name: foundIngredient.name } : { id, name: `Unknown ${masterType}` };
            });
        });

        const updatedFunctions: { id: string; name: string }[] = data.functions.map((id: any) => {
            const functionItem = functions.find(func => func.id === id);
            return functionItem ? { id, name: functionItem.name } : { id, name: `Unknown Function` };
        });

        const updatedAcceptability = data.acceptability.map(acceptabilityItem => {
            // Ensure 'qualifier' field exists, set to empty string if missing
            return {
                ...acceptabilityItem,
                qualifier: acceptabilityItem.qualifier || "",
            };
        });

        const keysToRemove = Object.keys(updatedMasters);
        const updatedData = { ...data };
        keysToRemove.forEach(key => {
            // @ts-ignore
            if (updatedData[key]) {
                // @ts-ignore
                delete updatedData[key];
            }
        });

        const latestIngredientId = Math.max(...ingredients.map(ingredient => parseInt(ingredient.id)));

        return { ...updatedData,
            functions: updatedFunctions,
            masters: updatedMasters,
            acceptability: updatedAcceptability,
            id: isUpdate ? ingredient?.id : (latestIngredientId + 1).toString(),
            createdOn: isUpdate ? ingredient?.createdOn : createdOn(),
            createdBy: isUpdate ? ingredient?.createdBy : user.email,
            updatedBy: user.email,
            updatedOn: createdOn(),
        }
    };

    const openAddFunctionModal = () => {
        const latestFuncId = functions.reduce((prev, current) => {
            const currentId = parseInt(current.id, 10);
            return !isNaN(currentId) ? Math.max(parseInt(prev, 10), currentId).toString() : prev;
        }, functions[0].id);
        setLatestFunctionId(parseInt(latestFuncId, 10) + 1);
        setIsAddFunctionModalVisible(true);
    };

    const handleAddFunction = (response: any) => {
        setIsAddFunctionModalVisible(false);
        getFunctionsHandler();
    };

    const handleSubmit = () => {
        // Generating new ingredient id
        form.validateFields().then((values) => {
            setLoading(true);
            // Display the loading message with a close button
            const loadingMessageKey = message.loading({
                content: `${isUpdate ? 'Updating' : 'Creating' } ingredient`,
                key: 'loadingMessage',
                duration: 0, // Set duration to 0 to prevent automatic dismissal
                onClose: () => setLoading(false), // Close the loading message on user interaction
            });
            // updating data
            const updatedValues = formatIngredient(values)
            const editIngredient = isUpdate ? updateIngredient : createIngredient
            editIngredient(updatedValues as Ingredient)
                .then((response) => {
                    loadingMessageKey();
                    setLoading(false);
                    if(!response) {
                        message.error(`Error ${isUpdate ? 'updating' : 'creating' } ingredient`, 10);
                        return;
                    }
                    message.success(`Ingredient ${isUpdate ? 'updated' : 'created' } successfully`, 10);
                    setIsFormChanged(false)
                    if(!isUpdate) {
                        clearIngredientsCache();
                        navigate(`/admin?ingredients=${response.name}`);
                        form.resetFields();
                    }
                })
        });
    }

    return (
        <>
        <CollapsableForm title="Ingredients Details">
            <Space direction="vertical">
                <Form form={form} onValuesChange={handleFormChange} layout="vertical">
                    <Form.Item label="Ingredient Name" name="name" required rules={[{ required: true, message: "provide an ingredient name" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Ingredient Classification" name="classification" required rules={[{ required: true, message: "select a classification" }]}>
                        <Radio.Group
                            options={classifications}
                            onChange={handleClassificationChange}
                            value={selectedClassification}
                        />
                    </Form.Item>
                    {
                        masters.includes(selectedClassification)
                    }
                    <Form.Item label="Masters" name="masters" valuePropName="checked">
                        <Select
                            mode={SelectModes.multiple}
                            placeholder={NORMAL_SEARCH}
                            onChange={handleMastersChange}
                            value={selectedMasters}
                        >
                            {
                                masters.map((master: string) => (
                                <Option key={master} value={master}>
                                    {master}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {
                        selectedMasters && selectedMasters.map((master: string) => {
                            return (
                                <Form.Item key={master} label={
                                    master === 'Alias' || master === 'Brand-name'
                                        ? `${master} for`
                                        : `${master} name for `
                                } name={master as string}>
                                    <Select
                                        placeholder={`Select ingredient...`}
                                        optionFilterProp="children"
                                        mode={SelectModes.multiple}
                                        onChange={handleSelectIngredient}
                                        showSearch
                                    >
                                        {
                                            ingredients
                                                .sort((a, b) =>
                                                    a.name.localeCompare(b.name, undefined, {
                                                        numeric: true,
                                                    })
                                                )
                                                .map((ingredient: Ingredient) => (
                                                    <Option key={ingredient.id} value={ingredient.id}>
                                                        {ingredient.name}
                                                    </Option>
                                                ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        })
                    }
                    {
                        classifications.includes(selectedClassification) && (
                            <Form.Item label="Type & Status" name="typeAndStatus">
                                <Form.List name="acceptability">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(( field, index) => (
                                                <Space key={field.key} style={displayFlex}>
                                                    <Space direction="vertical" style={displayFlex}>
                                                        <Space style={displayFlex}>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "type"]}
                                                                rules={[{ required: true, message: "Select a Type" }]}
                                                            >
                                                                <Select
                                                                    placeholder={"Select type..."}
                                                                    showSearch
                                                                    style={ingredientsCardTypeAndStatusStyle}
                                                                >
                                                                    {
                                                                        types.map((type: string) => (
                                                                            <Option key={type} value={type}>
                                                                                {type}
                                                                            </Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "status"]}
                                                                rules={[{ required: true, message: "Select a Status" }]}
                                                            >
                                                                <Select
                                                                    placeholder={"Select status..."}
                                                                    showSearch
                                                                    style={ingredientsCardTypeAndStatusStyle}
                                                                    onChange={() => setStatusUpdated(!isStatusUpdated)}
                                                                >
                                                                    {statuses.map((status: string) => (
                                                                        <Option key={status} value={status}>
                                                                            {status}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Space>
                                                        {
                                                            form.getFieldValue(["acceptability" , index, "status"])?.includes("qualifier") && (
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, "qualifier"]}
                                                                rules={[{ required: true, message: "Enter Qualifier" }]}
                                                            >
                                                                <Input placeholder="Enter Qualifier" />
                                                            </Form.Item>
                                                            )
                                                        }
                                                    </Space>
                                                    <MinusCircleTwoTone
                                                        onClick={() => remove(field.name)}
                                                        style={minusCircleTwoTone}
                                                    />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} icon={<PlusCircleTwoTone />}>
                                                    Add Type & Status
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        )
                    }
                    {
                        (
                            <Space style={displayFlex}>
                                <Form.Item label="Functions" name="functions">
                                    <Select
                                        mode={SelectModes.multiple}
                                        placeholder={NORMAL_SEARCH}
                                        onChange={handleFunctionChange}
                                        value={selectedFunctions}
                                        optionFilterProp="children"
                                        style={ingredientsCardFunctionsStyle}
                                        showSearch
                                    >
                                        {
                                            getUniqueItems(functions)
                                                .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,}))
                                                .map((func: Function) => (
                                                <Option key={func.id} value={func.id}>
                                                    {func.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                <Button type="dashed" onClick={() => openAddFunctionModal()} icon={<PlusCircleTwoTone />}>
                                    Add Function
                                </Button>
                            </Space>
                        )
                    }
                    <Form.Item label="Visibility" name="visibility" required rules={[{ required: true, message: "select visibility" }]}>
                        <Radio.Group
                            options={visibilities}
                            onChange={handleVisibilityChange}
                            value={selectedVisibility}
                        />
                    </Form.Item>
                    <Space>
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Space>
                </Form>
            </Space>
            <EditFunctions
                open={isAddFunctionModalVisible}
                onOk={handleAddFunction}
                onCancel={() => setIsAddFunctionModalVisible(false)}
                newFunctionId={(latestFunctionId ?? + 1).toString()}
            />
        </CollapsableForm>
    </>
    )
}

export default IngredientsCard;