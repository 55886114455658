import React from "react";
import {Collapse, Spin} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";

interface CollapsableFormProps {
    title: string;
    children: React.ReactNode;
}

const { Panel } = Collapse;

const CollapsableForm: React.FC<CollapsableFormProps> = (props, context) => {
    return (
        <Collapse
            defaultActiveKey={['1']} // By default, the first panel is open
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} // Custom expand icon with rotation
            expandIconPosition="end"
        >
            <Panel header={props.title} key="1">
                {props.children}
            </Panel>
        </Collapse>
    );
}

export default CollapsableForm;