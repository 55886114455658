import {Ingredient} from "src/types/ingredient";
import {Function} from "src/types/function";
import {AM, CENTURY, months, PM} from "src/constants/appConstants";

type Item = Function | Ingredient

// Returns function/ingredient name by id
export function getNameById(items: Item[], id: string): string | undefined {
    const item = items.find((item: any) => item.id === id);
    return item ? item.name : undefined;
}

// Returns unique function/ingredient names
export const getItemNames = (items: Item[]): string[] => {
    return Array.from(new Set(items.map((item: { name: string; }) => item.name)));
};

// Return unique function/ingredient list
export const getUniqueItems = (items: Item[]): Item[] => {
    const uniqueNamesSet: Set<string> = new Set();
    const uniqueItems: Function[] | Ingredient[] = [];

    items.forEach((item: any) => {
        if (item.name && item.name.trim() !== '' && !uniqueNamesSet.has(item.name)) {
            uniqueNamesSet.add(item.name);
            uniqueItems.push(item);
        }
    });

    return uniqueItems;
};

// capitalize a string
export const capitalize = (str: string) => {
    return str.replace(/\b\w/g, (letter) => letter.toUpperCase());
};

// get name from email address
export const getName = (email: string) => {
    const name = email.split('@')[0];
    const firstName = name.split('.')[0].replace(/\d+$/, '');
    const lastName = name.split('.')[1]?.replace(/\d+$/, '');
    const fullName = `${firstName} ${lastName}`;
    return capitalize(fullName);
}

// convert string to 12-hour format time
export const convertTime = (time: string) => {
    // Split the input time into hours and minutes
    const [hours, minutes] = time.split(':');
    // Convert hours to a 12-hour format and determine AM/PM
    let convertedHours = parseInt(hours, 10);
    const amPm = convertedHours >= 12 ? PM : AM;
    if (convertedHours === 0) {
        convertedHours = 12; // Midnight (12:00 AM)
    } else if (convertedHours > 12) {
        convertedHours -= 12;
    }
    // Create the formatted time string
    return `${convertedHours}:${minutes} ${amPm}`;
}

// convert date string to month name and day, year format
export const convertDate = (date: string) => {
    // Split the input date into day, month, and year
    const [day, month, year] = date.split('/');
    // Convert month number to month name
    const monthName = months[parseInt(month, 10) - 1];
    // Create the formatted date string
    return `${monthName} ${day}, ${CENTURY}${year}`;
}

// convert date string to MM/DD/YYYY US format
export const convertDateUSFormat = (date: string) => {
    // Split the input date string into day, month, and year
    const parts = date.split('/');
    // Create a new Date object in MM/DD/YYYY format
    const newDate = new Date(2000 + parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    // Extract month, day, and year with leading zeros if necessary
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    const day = ('0' + newDate.getDate()).slice(-2);
    const year = newDate.getFullYear();
    return `${month}/${day}/${year}`;
}

// convert
export const createdOn = () => {
    // Get the current date in 'YYYY-MM-DDTHH:mm:ss.sssZ' format
    let dateTime = new Date().toISOString()

    // Convert the ISO date string to a Date object
    let dateObject = new Date(dateTime);

    // Extract the date components (day, month, year, hours, minutes) from the Date object
    let day = dateObject.getDate().toString().padStart(2, '0');
    let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    let year = dateObject.getFullYear().toString().substr(-2);
    let hours = dateObject.getHours().toString().padStart(2, '0');
    let minutes = dateObject.getMinutes().toString().padStart(2, '0');

    // Create the formatted date string in 'DD-MM-YY HH:mm' format
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const sortItems = (a: Item, b: Item) => {
    const getTitleType = (title: string) => {
        if (/^\d/.test(title)) {
            return 0; // Numbers
        } else if (/^[a-z]/.test(title)) {
            return 1; // Lowercase letters
        } else {
            return 2; // Uppercase letters
        }
    };

    const typeA = getTitleType(a.name);
    const typeB = getTitleType(b.name);

    if (typeA !== typeB) {
        return typeA - typeB;
    }

    return a.name.localeCompare(b.name);
};