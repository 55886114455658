import {apiConfig} from "src/config";
import {fetchWithToken, fetchWithTokenAndBody} from "src/api/authentication";
import {Ingredient, IngredientsFileRequest} from "src/types/ingredient";
import {DELETE, GET, PATCH, POST, PUT} from "src/constants/apiConstants";

const INGREDIENT_API_ENDPOINT = `${apiConfig.API_ENDPOINT}/ingredients`;

const getIngredients = async (): Promise<Ingredient[]> => {
    const response = await fetchWithToken(`${INGREDIENT_API_ENDPOINT}/list`, GET);
    if(response) return response.data;
    return [];
}

const getIngredientFile = async (request: IngredientsFileRequest): Promise<string> => {
    const response = await fetchWithTokenAndBody(`${INGREDIENT_API_ENDPOINT}/file`, POST, request);
    if(response) return response.data;
    return '';
}

const createIngredient = async (ingredient: Ingredient): Promise<Ingredient | null> => {
    const response = await fetchWithTokenAndBody(`${INGREDIENT_API_ENDPOINT}/entry`, PUT, ingredient);
    if (response) return response.data;
    return null;
}

const updateIngredient = async (ingredientData: Ingredient): Promise<Ingredient | null> => {
    const response = await fetchWithTokenAndBody(`${INGREDIENT_API_ENDPOINT}/entry`, PATCH, ingredientData);
    if (response) return response.data;
    return null;
}

const deleteIngredient = async (ingredientId: string): Promise<Ingredient | null> => {
    const response = await fetchWithToken(`${INGREDIENT_API_ENDPOINT}/entry/?id=${ingredientId}`, DELETE);
    if (response) return response.data;
    return null;
}

export {
    getIngredients,
    getIngredientFile,
    createIngredient,
    updateIngredient,
    deleteIngredient
}