import React, {useEffect, useState} from "react";
import {Button, Card, Divider, Space, Tooltip} from "antd";
import {useNavigate, useParams } from "react-router-dom";
import {getFunctionById} from "src/api/functionApi";
import {Function} from "src/types/function";
import EditFunctions from "src/components/EditFunctions";
import {DeleteTwoTone} from "@ant-design/icons";
import {RED} from "src/constants/colorContants";
import {deleteTwoToneStyle} from "src/constants/styleConstants";
import Delete from "src/components/common/Delete";
import {
    FUNCTION,
    FUNCTION_DESCRIPTION_PAGE,
    GLOSSARY_PAGE,
    HOME_PAGE,
    NOT_FOUND_PAGE
} from "src/constants/appConstants";
import "../css/style.css";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";

interface FunctionDescriptionProps {
    admin: boolean
}

export const FunctionDescription: React.FC<FunctionDescriptionProps> = ({admin}) => {
    const initialTime = Date.now();
    const [functionDetails, setFunctionDetails] = useState<Function>();
    const [isUpdated, setIsUpdated] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [isEditFunctionModalVisible, setIsEditFunctionModalVisible] = useState(false);
    const [isDeleteFunctionModalVisible, setDeleteFunctionModalVisible] = useState(false);

    useEffect(() => {
        if(!params["id"])
            return navigate(NOT_FOUND_PAGE)
        getFunctionById(params["id"])
            .then(func => {
                if(!func)
                    return navigate(NOT_FOUND_PAGE)
                setFunctionDetails(func);
            })
        const metricsPublisher = getMetricsPublisher();
        metricsPublisher.publishTimerMetric(FUNCTION_DESCRIPTION_PAGE.replace(':id', params["id"]),
          MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
        metricsPublisher.publishCounterMetric(FUNCTION_DESCRIPTION_PAGE.replace(':id', params["id"]), MetricKey.CLICKS, 1);
    }, [admin, isUpdated]);

    const openEditFunctionModal = () => {
        setIsUpdated(false);
        setIsEditFunctionModalVisible(true);
    };

    const handleEditFunction = () => {
        setIsEditFunctionModalVisible(false);
    };

    const handleUpdateFunctionDetails = (updatedFunction: Function) => {
        // Update the functionDetails state with the edited data
        setFunctionDetails(updatedFunction);
        setIsUpdated(true);
    };

    const handleDelete = () => {
        setDeleteFunctionModalVisible(false);
        navigate(GLOSSARY_PAGE);
    }

    return <div>
        <Card
            title={
                <div className="glossary-functions-header">
                    <span>{functionDetails?.name.capitalize()}</span>
                    {admin && <div>
                        <Button type="link" onClick={() => openEditFunctionModal()}>Edit</Button>
                        <Space>
                            <Tooltip title="Delete">
                                <DeleteTwoTone
                                    twoToneColor={RED}
                                    style={deleteTwoToneStyle}
                                    onClick={() => setDeleteFunctionModalVisible(true)}
                                />
                            </Tooltip>
                        </Space>
                    </div>}
                </div>
            }
            loading={!functionDetails}
        >
            <div dangerouslySetInnerHTML={{ __html: functionDetails?.description ?? "" }}/>
        </Card>

        <EditFunctions
            open={isEditFunctionModalVisible}
            onOk={(updatedFunction) => {
                handleUpdateFunctionDetails(updatedFunction);
                handleEditFunction()}}
            onCancel={() => setIsEditFunctionModalVisible(false)}
            initialData={functionDetails}
            newFunctionId={params["id"]}
        />

        {
            functionDetails !== undefined && (
                <Delete
                    open={isDeleteFunctionModalVisible}
                    onConfirm={handleDelete}
                    onCancel={() => setDeleteFunctionModalVisible(false)}
                    type={FUNCTION}
                    id={functionDetails?.id}
                />
            )
        }
    </div>
}