import {Ingredient} from "src/types/ingredient";

const calculateProximity = (entry: string, keywords: string[]) => {
    const indices = keywords.map(keyword => entry.indexOf(keyword.toLowerCase()));
    const validIndices = indices.filter(index => index !== -1);

    if (validIndices.length === 0) {
        return Number.MAX_SAFE_INTEGER; // Move entries without keywords to the end
    }

    return Math.min(...validIndices);
}

export const proximitySort = (entries: Ingredient[], keywords: string[]) => {
    return entries.sort((a, b) => {
        const proximityA = calculateProximity(a.name.toLowerCase(), keywords);
        const proximityB = calculateProximity(b.name.toLowerCase(), keywords);

        return proximityA - proximityB;
    });
}