export enum Role {
    ADMIN = "Admin",
    SUBSCRIBER = "Subscriber"
}

export enum Visibility {
    DRAFT = "Draft",
    PUBLISH = "Publish"
}

export enum AcceptabilityType {
    ACCEPTABLE = "acceptable",
    UNACCEPTABLE = "unacceptable",
    ACCEPTABLE_WITH_QUALIFIER = "acceptable-with-qualifier",
    UNACCEPTABLE_WITH_QUALIFIER = "unacceptable-with-qualifier",
}

export enum Actions {
    ADD = "Add",
    EDIT = "Edit",
    DELETE = "Delete",
    UPDATE = "Update"
}