import {fetchWithToken, fetchWithTokenAndBody} from "src/api/authentication";
import {apiConfig} from "src/config";
import {Function} from "src/types/function";
import {DELETE, GET, PATCH, PUT} from "src/constants/apiConstants";

const FUNCTION_API_ENDPOINT = `${apiConfig.API_ENDPOINT}/ingredients/function`;

const getFunctions = async (): Promise<Function[] | null> => {
    const response = await fetchWithToken(FUNCTION_API_ENDPOINT, GET);
    if(response) return response.data;
    return null;
}

const getFunctionById = async (functionId: string): Promise<Function | null> => {
    const response = await fetchWithToken(`${FUNCTION_API_ENDPOINT}?id=${functionId}`, GET);
    if(response) return response.data;
    return null;
}

const createFunction = async (functionData: Function): Promise<Function | null> => {
    const response = await fetchWithTokenAndBody(FUNCTION_API_ENDPOINT, PUT, functionData);
    if (response) return response.data;
    return null;
}

const updateFunction = async (functionData: Function): Promise<Function | null> => {
    const response = await fetchWithTokenAndBody(FUNCTION_API_ENDPOINT, PATCH, functionData);
    if (response) return response.data;
    return null;
}

const deleteFunction = async (functionId: string): Promise<Function | null> => {
    const response = await fetchWithToken(`${FUNCTION_API_ENDPOINT}?id=${functionId}`, DELETE);
    if (response) return response.data;
    return null;
}

export {
    getFunctions,
    getFunctionById,
    createFunction,
    updateFunction,
    deleteFunction
}