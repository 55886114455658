import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {HOME_PAGE} from "src/constants/appConstants";

function NotFoundPage() {

    const navigate = useNavigate();

    useEffect(() => {
        const handlePopstate = () => {
            // Replace the current URL with the root path
            navigate(HOME_PAGE, { replace: true });
        };
        // Attach the popstate event listener
        window.onpopstate = handlePopstate;

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    return (
        <div className={"not-found-page"}>
            <h2>404: Page Not Found</h2>
        </div>
    );
}

export default NotFoundPage;
