import {Card} from "antd";
import React from "react";

export default function BottomTermsCard() {
    return <Card>
        Whole Foods Market’s Ingredient Standards do not alter a supplier’s responsibility for ensuring that the products they sell to Whole Foods Market comply with all applicable laws.
        <br/><br/>
        Ingredient lists are periodically updated, please check the list for updates regularly.
        <br/><br/>
        This site is accessible to internal Team Members only. Supplier-facing ingredient lists and other requirements can be found on the Quality Standards page of the Whole Foods Market Supplier Portal.
    </Card>
}