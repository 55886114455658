import React, { useEffect, useState } from "react";
import type {ColumnProps} from "antd/es/table";
import {Table} from "antd";
import {Ingredient} from "src/types/ingredient";
import {Link} from "react-router-dom";
import {capitalize} from "src/utils/commonUtils";
import {ingredientsTableStyle} from "src/constants/styleConstants";

export function IngredientsTable(props: { data: Ingredient[], columns: ColumnProps<Ingredient>[], loading: boolean, admin: boolean }) {
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        // Reset the page number when location.pathname changes
        setCurrentPage(1);
    }, [location.pathname]);

    const handlePaginationChange = (page: React.SetStateAction<number>) => {
        setCurrentPage(page); // Save or use the page number as needed
    };

    return <Table
        loading={props.loading}
        columns={props.columns}
        expandable={props.admin ? {} : {
            expandedRowRender: record => {
                // display masters of ingredients as expanded entry
                return <div>
                    {
                        Object.keys(record.masters).map(masterKey =>
                            <p>{capitalize(masterKey)}: <br/>
                              {record.masters[masterKey].map((masterRecord: { [x: string]: string; }) =>
                                <Link to={`/?ingredients=${masterRecord["name"]}`}>{masterRecord["name"].toUpperCase()}<br/></Link>)}
                            </p>
                        )}
                    {Array.from(record.functions).length > 0 ? <p>Functions: <br/>
                        {
                            Array.from(record.functions).map(functionMeta =>
                                // @ts-ignore
                                <><Link to={`/glossary/${functionMeta["id"]}`}>{functionMeta["name"].toUpperCase()}</Link><br/></>
                            )
                        }
                    </p> : null}
                </div>
            },
            expandRowByClick: true
        }}
        style={ingredientsTableStyle}
        dataSource={props.data}
        rowKey={record => record.id}
        pagination={{
            current: currentPage,
            onChange: handlePaginationChange,
          }}
        scroll={{x: "calc(700px + 50%)"}}
    />;
}