import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    ADMIN_DASHBOARD,
    ADMIN_PAGE,
    HOME_PAGE, NOT_FOUND_PAGE,
    TOOLTIP_ADMIN,
    TOOLTIP_INGREDIENT
} from "src/constants/appConstants";
import {Menu, Switch, Tooltip} from "antd";
import {navigationAdminToggleStyle} from "src/constants/styleConstants";

interface SwitcherProps {
    admin: boolean;
}

export const Switcher : React.FC<SwitcherProps> = ({admin}) => {
    const navigate = useNavigate();
    const [tooltipTitle, setToolTipTitle] = useState(TOOLTIP_ADMIN);
    const [isSwitchToggled, setIsSwitchToggled] = useState(false);
    const [isSwitchVisible, setSwitchVisible] = useState<boolean>(false);

    useEffect(() => {
        if(location.pathname === HOME_PAGE || location.pathname.includes(ADMIN_PAGE)) {
            if(location.pathname === HOME_PAGE) {
                setIsSwitchToggled(false);
                setToolTipTitle(TOOLTIP_ADMIN);
            } else {
                setIsSwitchToggled(true);
                setToolTipTitle(TOOLTIP_INGREDIENT);
            }
            setSwitchVisible(true);
        }
        else if(location.pathname === NOT_FOUND_PAGE) {
            history.pushState(null, "", HOME_PAGE);
        }
        else {
            setSwitchVisible(false);
        }
    }, [location.pathname]);

    const handleSwitchChange = (checked: boolean) => {
        // Update the switch state
        setIsSwitchToggled(checked);

        // Navigate to different URLs based on the switch state
        if (checked) {
            setToolTipTitle(TOOLTIP_INGREDIENT);
            navigate(ADMIN_PAGE);
        } else {
            setToolTipTitle(TOOLTIP_ADMIN);
            navigate(HOME_PAGE);
        }
    };

    return <>
        { admin && isSwitchVisible ? (
            <Menu.Item style={navigationAdminToggleStyle}>
                <Tooltip title={tooltipTitle}>
                    <Switch
                        checked={isSwitchToggled}
                        onChange={handleSwitchChange}
                        checkedChildren={ADMIN_DASHBOARD}
                        unCheckedChildren={ADMIN_DASHBOARD}
                    />
                </Tooltip>
            </Menu.Item>
            ) : null }
    </>
}