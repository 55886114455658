import { filteredIdsForApi } from "src/containers/SearchContainer";
import {Ingredient} from "src/types/ingredient";
import {getName} from "src/utils/commonUtils";

export const isIngredientAvailable = (search: string, searchIn: Ingredient[]) => {
    return !!searchIn.find(ingredient => ingredient.name.toLowerCase().includes(search.toLowerCase()));
}

export const filterIngredients = (
    ingredients: Ingredient[],
    classificationFilters: string[],
    authorFilters: string[],
    visibilityFilters: string[],
    searchedValues: any[],
    filterByCharValues: string[],
    filterTypes: string[],
) => {
    const filteredIngredients: Ingredient[] = ingredients.filter(ingredient => {
        const authorName = getName(ingredient.createdBy);
        if (!(classificationFilters.includes(ingredient.classification) && authorFilters.includes(authorName) && visibilityFilters.includes(ingredient.visibility))) {
            return false;
        }
        if (!(ingredient.acceptability.find(acc => filterTypes.includes(acc.type)))) {
            return false;
        }
        if (searchedValues.length === 0 && filterByCharValues.length === 0) {
            return true;
        }
        if (
            searchedValues.find(search => isIngredientAvailable(search, [ingredient])) ||
            filterByCharValues?.includes(ingredient.name)
        ) {
            return true;
        }
        return false;
    });
    // Clear the set before adding new values
    filteredIdsForApi.clear();
    // Add new values to the set
    filteredIngredients.forEach(ingredient => filteredIdsForApi.add(ingredient.id));
    return filteredIngredients;
};
