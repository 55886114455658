import React from "react";
import {Select} from "antd";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import {INGREDIENT_SEARCH, SelectModes} from "src/constants/appConstants";
import {searchBarStyle} from "src/constants/styleConstants";

export function SearchBar(props: { data: JSX.Element[], searchValue: string, searchedValues: string[], onChange: (values: string[]) => void, onSearch: (value: string) => void,
    onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    tagRender: (props: CustomTagProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>
}) {
    return <div className={"search-bar"}>
        <Select
            mode={SelectModes.multiple}
            placeholder={INGREDIENT_SEARCH}
            style={searchBarStyle}
            value={props.searchedValues}
            onChange={props.onChange}
            onSearch={props.onSearch}
            searchValue={props.searchValue}
            onInputKeyDown={props.onInputKeyDown}
            tagRender={props.tagRender}
            allowClear>
            {props.data}
        </Select>
    </div>;
}