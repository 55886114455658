import {Card} from "antd";
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, QuestionCircleFilled} from "@ant-design/icons";
import {ACCEPTABILITY_ICON_FONT_SIZE} from "src/constants/sizeContants";
import {GREEN, GREY, RED, YELLOW} from "src/constants/colorContants";
import React from "react";

export function LegendsCard() {
    return <Card title="Legends">
        <p style={{ display: "flex", alignItems: "center", gap: '0.5em'}}>
            <CheckCircleFilled style={{fontSize: ACCEPTABILITY_ICON_FONT_SIZE, color: GREEN}}/>Acceptable
        </p>
        <p style={{ display: "flex", alignItems: "center", gap: '0.5em'}}>
            <CloseCircleFilled style={{fontSize: ACCEPTABILITY_ICON_FONT_SIZE, color: RED}}/>Unacceptable
        </p>
        <p style={{ display: "flex", alignItems: "center", gap: '0.5em'}}>
            <ExclamationCircleFilled
                style={{fontSize: ACCEPTABILITY_ICON_FONT_SIZE, color: YELLOW}}/>
            <div>Restricted<br/>
                <span style={{fontSize: '0.8em'}}>Hover over icon for details</span>
            </div>
        </p>
        <p style={{ display: "flex", alignItems: "center", gap: '0.5em'}}>
            <QuestionCircleFilled style={{fontSize: ACCEPTABILITY_ICON_FONT_SIZE, color: GREY}}/>
            <div>Not Reviewed<br/>
                <span style={{fontSize: '0.8em'}}>Requires approval</span>
            </div>
        </p>
    </Card>;
}