import {getIngredients} from "src/api/ingredientApi";
import {Ingredient} from "src/types/ingredient";

let ingredientsCache: Ingredient[] = [];

const getCachedIngredients = async () : Promise<Ingredient[]> => {
    if(ingredientsCache.length === 0) {
        const ingredients = await getIngredients();
        ingredientsCache.push(...ingredients);
        return ingredients;
    }
    return Promise.resolve(ingredientsCache);
}

const clearIngredientsCache = (): void => {
    ingredientsCache = [];
};

export { getCachedIngredients, clearIngredientsCache };